document.addEventListener('DOMContentLoaded', function () {
    document.body.addEventListener('htmx:confirm', function (evt) {
        if (evt.detail.question) {
            evt.preventDefault();
            $("#global_confirmation_modal .modal-body").html(evt.detail.question)
            $("#global_confirmation_modal").modal("show")
            $("#global_confirmation_modal_confirm_button").on('click', () => {
                return evt.detail.issueRequest(true);
            })
        }
    });
});