import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function () {
    var modal_element = document.getElementById('country_modal__js');
    if (modal_element) {

        // open modal with a question
        var $modal = new Modal(modal_element, {
            backdrop: 'static',
            keyboard: false
        });
        $modal.show();

        // remember that modal was already closed
        modal_element.addEventListener('click', function (event) {
            if (event.target.tagName === 'A') {
                // hodnota z core.settings.COOKIE_COUNTRY_MODAL
                setCookie('country_modal', 1, 0);
            }
        });

    }
});
