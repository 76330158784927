import jQuery from 'jquery';
import './jquery.oska-spinner';
import './modal';
import './country_modal';
import './authorize_net';


window.htmx = require('htmx.org').default;

window.$ = window.jQuery = jQuery
